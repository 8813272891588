import BaseMethods from '../BaseMethods';
import {getData} from "@/api/v2/utils/DataHandler";

export default new (class extends BaseMethods {
    endpointRoute = '/api/v2/tasks';

    async index(projectId, params) {
        const route = `/api/v2/projects/${projectId}/tasks`;

        return this.asJson().get(route, {params}).then(getData);
    }

    async show(id, params) {
        const route = `${this.endpointRoute}/${id}`;

        return this.asJson().get(route, {params}).then(getData);
    }

    async acknowledge(id) {
        const route = `${this.endpointRoute}/${id}/acknowledge`;

        return this.asJson().get(route).then(getData);
    }

    async file(id, data) {
        const route = `${this.endpointRoute}/${id}/file`;

        return this.asFormData().post(route, data).then(getData);
    }

    async downloadFile(id) {
        return this.asDownload(`${this.endpointRoute}/${id}/download-file`);
    }

    async sign(id, data) {
        const route = `${this.endpointRoute}/${id}/sign`;

        return this.asFormData().post(route, data).then(getData);
    }

    async upload(id, data) {
        const route = `${this.endpointRoute}/${id}/upload`;

        return this.asFormData().post(route, data).then(getData);
    }

    async reminder(id) {
        const route = `${this.endpointRoute}/${id}/reminder`;

        return this.asFormData().get(route).then(getData);
    }

    async downloadUploadedFile(id) {
        return this.asDownload(`${this.endpointRoute}/${id}/download-uploaded-file`);
    }

    async deleteUploadedFile(id) {
        const route = `${this.endpointRoute}/${id}/delete-uploaded-file`;

        return this.asJson().delete(route).then(getData);
    }

    async approve(id) {
        const route = `${this.endpointRoute}/${id}/approve`;

        return this.asJson().get(route).then(getData);
    }

    async reject(id, data) {
        const route = `${this.endpointRoute}/${id}/reject`;

        return this.asJson().post(route, data).then(getData);
    }
})();
